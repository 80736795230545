
import { Options, Prop } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import NavigationMixin from "@/mixins/NavigationMixin";
import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";
import FavoriteButton from "@/components/FavoriteButton.vue";
import FavoriteActionMixin from "@/mixins/FavoriteActionMixin";
import QuestionHint from "@/components/QuestionHint.vue";
import NextButton from "@/components/NextButton.vue";

const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");

@Options({
  components: {
    NextButton,
    QuestionHint,
    FavoriteButton,
    BackButton,
    Question,
    DefaultLayout,
  },
})
export default class Questions extends mixins(NavigationMixin, QuestionAnswersMixin, FavoriteActionMixin) {
  @Prop()
  public studentEducation!: any;

  @TheoryQuestionsModule.Action("getOne")
  public nextQuestionAction: any;

  @TheoryQuestionsModule.Action("findOne")
  public findQuestionAction: any;

  @TheoryQuestionsModule.Action("resetSave")
  public resetTheoryQuestionsQuestion: any;

  @TheoryQuestionsModule.Getter("getDataItem")
  protected question: any;

  @TheoryQuestionsModule.Getter("getIsLoading")
  public loadingQuestion: any;

  @TheoryQuestionsModule.Action("setMarkFavouriteSuccess")
  public setMarkFavouriteSuccessAction: any;

  @StudentQuestionAnswersModule.Action("getStats")
  public getStatsAction: any;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetAnswerQuestion: any;

  @StudentQuestionAnswersModule.Getter("getSuccess")
  public answerSuccess: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public loadingAnswer: any;

  @StudentQuestionAnswersModule.Getter("getStats")
  public stats: any;

  private showSolutionButton = false;
  private showCorrectAnswers = false;
  private previousQuestionIds: Array<number> = [];

  get loading(): boolean {
    return this.loadingQuestion || this.loadingAnswer;
  }

  private goToNext(): void {
    this.showCorrectAnswers = false;
    this.previousQuestionIds.push(this.question.id);
    this.reset();
    this.loadNextQuestion();
    this.loadStats();
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  private loadNextQuestion(): void {
    this.nextQuestionAction({
      resource: "theory-questions",
      extraPath: "next",
      hideSuccessMessage: true,
      params: { studentEducationId: this.studentEducation },
    });
  }

  private loadStats(): void {
    this.getStatsAction(this.studentEducation);
  }

  public mounted(): void {
    this.loadNextQuestion();
    this.loadStats();
  }

  private reset(): void {
    this.resetTheoryQuestionsQuestion();
    this.setMarkFavouriteSuccessAction(null);
  }

  public unmounted() {
    this.reset();
  }
}
